:root {
  --navbar-color: #9d9d9d;
  --navbar-color-active: var(--navbar-color);
  --navbar-bg: #222;
  --navbar-bg-active: #080808;
  --navbar-bg-border-active: none;
  --navbar-image: linear-gradient(to bottom, #3c3c3c 0, #222 100%);
  --navbar-height: 50px;
  --navbar-border-bottom: none;
}

@media (prefers-color-scheme: dark) {
  :root {
    --navbar-color: #969ba1;
    --navbar-color-active: #8ab4f8;
    --navbar-bg: var(--bg-color);
    --navbar-bg-active: none;
    --navbar-bg-border-active: 3px solid var(--navbar-color-active);
    --navbar-image: none;
    --navbar-border-bottom: 1px solid var(--border-color);
  }
}

.navbar {
  background-color: var(--navbar-bg);
  background-image: var(--navbar-image);
  text-shadow: 0 -1px rgba(0, 0, 0, .2);
  min-height: var(--navbar-height);
  border-bottom: var(--navbar-border-bottom);
}

.main-nav {
  list-style: none;
  display: none;
}

.main-nav.active {
  display: block;
}

.navbar-brand {
  font-size: 18px;
  font-weight: 600;
}

.navbar-toggle {
  color: var(--navbar-color);
  padding: 0 6px;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 14px;
}

.navbar a, .navbar-dropdown {
  color: var(--navbar-color);
  padding: 15px;
  text-decoration: none;
  display: block;
}

.navbar-brand a {
  padding: 12px 15px;
  display: inline-block;
}

.navbar-dropdown {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.navbar-dropdown-button {
  display: none;
}

.navbar li {
  color: var(--navbar-color);
}

li.navbar-active > :first-child {
  background-color: var(--navbar-bg-active);
  color: var(--navbar-color-active);
  border-bottom: var(--navbar-bg-border-active);
}

li.navbar-active a, li.navbar-active span, .navbar a:hover {
  color: #fff;
}

.navbar-dropdown-content a {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  color: var(--navbar-color) !important;
}

.navbar-dropdown-content a:hover {
  color: #fff !important;
}

.navbar-mailbox {
  padding: 15px;
}

.navbar-mailbox input {
  width: 250px;
  border-radius: 4px;
  padding: 5px 10px;
}

@media screen and (min-width: 1000px) {
  .main-nav {
    height: var(--navbar-height);
  }

  .navbar {
    background: none;
    align-items: center;
    display: flex;
  }

  .main-nav {
    flex-grow: 2;
    padding: 0;
    line-height: 20px;
    display: flex;
  }

  .main-nav.active {
    display: flex;
  }

  .navbar-bg {
    background-color: var(--navbar-bg);
    background-image: var(--navbar-image);
    height: var(--navbar-height);
    width: 100%;
    z-index: -1;
    grid-area: 1 / 1 / auto / 4;
  }

  .navbar-toggle {
    display: none;
  }

  .navbar-brand {
    margin-left: -15px;
  }

  .navbar-mailbox {
    order: 1;
    padding: 9px 0 8px !important;
  }

  .navbar-dropdown-container {
    margin: 0 auto;
  }

  .navbar-dropdown {
    max-width: 350px;
    padding: 15px 19px 15px 25px;
  }

  .navbar-dropdown-button {
    color: var(--navbar-color);
    background: none;
    border: none;
    margin-left: 3px;
    display: inline;
  }

  .navbar-dropdown-content {
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    min-width: 160px;
    text-shadow: none;
    z-index: 1;
    border-radius: 4px;
    display: none;
    position: absolute;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
  }

  .navbar-dropdown-container[aria-expanded="true"] .navbar-dropdown-content {
    display: block;
  }

  .navbar-dropdown-content a {
    padding: 5px 15px;
    color: var(--primary-color) !important;
  }

  .navbar-dropdown-content a:hover {
    background-color: var(--selected-color);
    color: var(--primary-color) !important;
  }
}

/*# sourceMappingURL=index.503e5a5e.css.map */
