/** NAV BAR */

:root {
  --navbar-color: #9d9d9d;
  --navbar-color-active: var(--navbar-color);
  --navbar-bg: #222;
  --navbar-bg-active: #080808;
  --navbar-bg-border-active: none;
  --navbar-image: linear-gradient(to bottom, #3c3c3c 0, #222 100%);
  --navbar-height: 50px;
  --navbar-border-bottom: none;
}

@media (prefers-color-scheme: dark) {
  :root {
    --navbar-color: #969ba1;
    --navbar-color-active: #8ab4f8;
    --navbar-bg: var(--bg-color);
    --navbar-bg-active: none;
    --navbar-bg-border-active: 3px solid var(--navbar-color-active);
    --navbar-image: none;
    --navbar-border-bottom: 1px solid var(--border-color);
  }
}


.navbar {
  background-color: var(--navbar-bg);
  background-image: var(--navbar-image);
  text-shadow: 0 -1px 0 rgba(0,0,0,0.2);
  min-height: var(--navbar-height);
  border-bottom: var(--navbar-border-bottom);
}

.main-nav {
  display: none;
  list-style: none;
}

.main-nav.active {
  display: block;
}

.navbar-brand {
  font-size: 18px;
  font-weight: 600;
}

.navbar-toggle {
  color: var(--navbar-color);
  font-size: 24px;
  padding: 0 6px;
  position: absolute;
  top: 10px;
  right: 14px;
}

.navbar a,
.navbar-dropdown {
  color: var(--navbar-color);
  display: block;
  padding: 15px;
  text-decoration: none;
}

/* This takes precendence over .navbar a above */
.navbar-brand a {
  display: inline-block;
  padding: 12px 15px;
}

.navbar-dropdown {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navbar-dropdown-button {
  display: none;
}

.navbar li {
  color: var(--navbar-color);
}

li.navbar-active > *:first-child {
  background-color: var(--navbar-bg-active);
  color: var(--navbar-color-active);
  border-bottom: var(--navbar-bg-border-active);
}

li.navbar-active a,
li.navbar-active span,
.navbar a:hover {
  color: #ffffff;
}

.navbar-dropdown-content a {
  color: var(--navbar-color) !important;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navbar-dropdown-content a:hover {
  color: #ffffff !important;
}

.navbar-mailbox {
  padding: 15px;
}

.navbar-mailbox input {
  border-radius: 4px;
  padding: 5px 10px;
  width: 250px;
}

@media screen and (min-width: 1000px) {
  .main-nav {
    height: var(--navbar-height);
  }

  .navbar {
    align-items: center;
    background: none;
    display: flex;
  }

  .main-nav {
    display: flex;
    flex-grow: 2;
    line-height: 20px;
    padding: 0;
  }

  .main-nav.active {
    display: flex;
  }

  .navbar-bg {
    background-color: var(--navbar-bg);
    background-image: var(--navbar-image);
    grid-column: 1 / 4;
    grid-row: 1;
    height: var(--navbar-height);
    width: 100%;
    z-index: -1;
  }

  .navbar-toggle {
    display: none;
  }

  .navbar-brand {
    margin-left: -15px;
  }

  .navbar-mailbox {
    order: 1;
    padding: 9px 0 8px 0 !important;
  }

  .navbar-dropdown-container {
    margin: 0 auto;
  }

  .navbar-dropdown {
    padding: 15px 19px 15px 25px;
    max-width: 350px;
  }

  .navbar-dropdown-button {
    background: none;
    border: none;
    color: var(--navbar-color);
    display: inline;
    margin-left: 3px;
  }

  .navbar-dropdown-content {
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0,0,0,.05);
    display: none;
    min-width: 160px;
    position: absolute;
    text-shadow: none;
    z-index: 1;
  }

  .navbar-dropdown-container[aria-expanded="true"] .navbar-dropdown-content {
    display: block;
  }

  .navbar-dropdown-content a {
    color: var(--primary-color) !important;
    padding: 5px 15px;
  }

  .navbar-dropdown-content a:hover {
    color: var(--primary-color) !important;
    background-color: var(--selected-color);
  }
}
